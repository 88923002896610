<template>
  <div class="about">
    <img src="@/static/images/about_bg.png" class="mb-16 w-full" alt="" />
    <div class="container">
      <h1 style="font-size: 3.75rem" class="font-bold text-center mb-16">
        品牌介绍
      </h1>
      <p class="mb-2 text-333 introduce text-indent-2">
        湖南世阳智能科技有限公司是一家快速成长、锐意进取、专业研发物联网设备和解决方案提供商，智能家居生态系统提供商，研发人员占比超过60%，已获得多项国家/国际发明专利，已构建覆盖从传感器、控制器到云计算、等各种应用的智能家居产品生态体系，已自主研发多款高质量产品，生态合作产品更是超过2000余款。。
      </p>
      <p class="mb-2 text-333 introduce">
        公司以人为本,以客户为中心，以需求为导向，以服务为宗旨;以创新、专业、求实、诚信、和谐为经营理念。创新科技，最专业的技术。在物联网传感器、控制器、移动物联网、云计算和等几大领域都进行了长期探索和不懈积累。凭借在物体感知、学习、控制等领域的综合优势，现已成为整个行业技术较专业的网络公司，迅速发展成为网络科技领域的后起之秀，成为物联网智能家居产业的主要推动者之一。
      </p>
      <p class="mb-20 text-333 introduce">
        世阳智能成立于2021年2月，世阳智能研发的产品可以运用各类传感器实现智能照明、智能控制、智能安防、智能遮阳等系统的智能自动化,并把所有这些集成在―个生态系统中。
      </p>
      <img src="@/static/images/company_bgpng.png" class="mb-60" alt="" />
    </div>
    <Cooperation />
    <CooperationRight />
    
    <Smart />
  </div>
</template>
<script>
import Cooperation from "@/components/Home/Cooperation/Cooperation.vue";
import CooperationRight from "@/components/Home/Cooperation/CooperationRight.vue";
import Smart from "@/components/Home/Smart/Smart.vue";

export default {
  components: {
    Cooperation,
    CooperationRight,
    Smart,
  },
};
</script>
<style scoped>
.introduce {
  font-size: 1.375rem;
  font-weight: 500;
}
.text-indent-2{
  text-indent: 2em;
}
</style>
